<template>
  <b-row class="match-height">
    <b-col
      v-for="business in businessData"
      :key="business.id"
      lg="3"
      md="12"
    >
      <b-card
        class="card-profile disabled"
      >
        <h3>
          <b-link
            :to="{ name: 'services-business-details', params: { id: business.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ business.name }}
          </b-link>
        </h3>
        <h6 class="text-muted">
          {{ business.dateCreated }}
        </h6>
        <b-badge
          class="profile-badge"
          :variant="`light-${businessStatus(business.status).variant}`"
        >
          {{ business.status }}
        </b-badge>
        <h4>
          {{ business.businessId }}
        </h4>
        <hr class="mb-2">

        <!-- follower projects rank -->
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h6 class="text-muted font-weight-bolder">
              Invoices
            </h6>
            <h3 class="mb-0">
              {{ business.invoices.length }}
            </h3>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Payments
            </h6>
            <h3 class="mb-0">
              {{ business.payments.length }}
            </h3>
          </div>
        </div>
        <!--/ follower projects rank -->
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BBadge, BRow, BCol, BLink,
} from 'bootstrap-vue'
import AppUtils from '@/auth/jwt/useJwt'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BLink,
  },
  data() {
    return {
      businessData: [],
    }
  },
  created() {
    // fetch the businesses
    AppUtils.fetchBusinesses().then(res => { this.businessData = res.data.data })
      .catch(
        error => {
          let message

          if (error.response) {
            message = error.response.data.message
          } else if (error.request) {
            message = 'No response received from server'
          } else {
            message = 'An error has occurred'
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'SBP Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Error: ${message}`,
            },
          })
        },
      )
  },
  setup() {
    const businessStatus = status => {
      if (status === 'Active') return { variant: 'success', active: true }
      return { variant: 'danger', active: false }
    }

    return {
      businessStatus,
    }
  },
}

</script>
